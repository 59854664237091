<template>
  <div class="declare">
    <div class="banner-size">
      <div class="banner-limit banner-info">
        <span class="banner-fMax">申报详情</span>
        <span class="banner-fMin banner-other">送政策、送服务，助推企业发展</span>
      </div>
    </div>
    <div class="declare-container width-limit" ref="declareContainer">
      <div class="d-container">
        <div v-if="sxmc" class="d-title">
          {{ sxmc }}
        </div>
        <!-- <Tabs v-model="tabsValue">
           <TabPane
             v-for="item in tabs"
             :key="item.name"
             :label="item.label"
             :name="item.name"
           >
           </TabPane>
         </Tabs>-->

        <!-- <el-steps :active="stepNum" align-center>
          <el-step title="填写基本信息"></el-step>
          <el-step title="上传材料"></el-step>
          <el-step title="提交成功"></el-step>
        </el-steps> -->
        <el-steps :active="stepNum" align-center>
          <el-step title="申请告知"></el-step>
          <el-step title="确认基本信息，填写表单"></el-step>
          <el-step title="上传材料"></el-step>
          <el-step title="确认"></el-step>
          <el-step title="提交成功"></el-step>
        </el-steps> 
          <!-- 申请告知 -->
					<ApplicationNotification  
          v-show="stepNum === 0"
					class="step-show d-base-contianer"
					ref="notificationIsconfirm"
					></ApplicationNotification> 
        <div v-show="stepNum === 1 || stepNum === 3" class="d-base-contianer">
          <el-tabs type="border-card">
              <el-tab-pane label="基本信息">
                 <h3>申请人（法人）信息</h3>
                  <div v-if="tableBaseInfo.length>0">
                  <div class="formItem">
                  <span class="itemTitle">{{tableBaseInfo[0].title}} <span
                      class="required">*</span> </span>
                      <el-input class="matchParent" disabled v-model="tableBaseInfo[0].content">
                      </el-input>
                  </div>
                  <div class="formItem">
                  <span class="itemTitle">{{tableBaseInfo[1].title}} <span
                      class="required">*</span> </span>
                      <div class="matchParent flex-row-between">
                      <el-select value="1" disabled>
                          <el-option label="统一社会信用代码" value="1"></el-option>
                      </el-select>
                      <el-input class="matchParent" style="margin-left: 10px" disabled
                                  v-model="tableBaseInfo[1].content">
                      </el-input>
                      </div>
                  </div>
                  <div class="formItem">
                  <span class="itemTitle">{{tableBaseInfo[2].title}} <span
                      style="color: red">*</span> </span>
                      <el-input class="matchParent" v-model="tableBaseInfo[2].content">
                      </el-input>
                  </div>
                  <div class="formItem">
                  <span class="itemTitle">{{tableBaseInfo[3].title}} <span
                      style="color: red">*</span> </span>
                      <el-input class="matchParent" v-model="tableBaseInfo[3].content">
                      </el-input>
                  </div>
                  <div class="formItem">
                  <span class="itemTitle">{{tableBaseInfo[4].title}} <span
                      style="color: red">*</span> </span>
                      <div class="matchParent flex-row-between">
                      <!-- <el-select value="1">
                          <el-option label="身份证" value="1"></el-option>
                      </el-select> -->
                      <el-input class="matchParent" style="margin-left: 10px"
                        v-model="tableBaseInfo[4].content">
                      </el-input>
                      </div>
                  </div>
                  </div> 
                  <h3>经办人信息</h3>
                    <div v-if="tableAgentInfo.length>0">
                        <div class="formItem">
                        <span class="itemTitle">{{tableAgentInfo[0].title}} <span
                            class="required">*</span> </span>
                            <el-input class="matchParent" disabled v-model="tableAgentInfo[0].content">
                            </el-input>
                        </div>
                        <div class="formItem">
                        <span class="itemTitle">{{tableAgentInfo[1].title}} <span
                            style="color: red">*</span> </span>
                            <div class="matchParent flex-row-between">
                            <el-select value="1" disabled>
                                <el-option label="身份证" value="1"></el-option>
                            </el-select>
                            <el-input class="matchParent" style="margin-left: 10px" disabled
                                v-model="tableAgentInfo[1].content">
                            </el-input>
                            </div>
                        </div>
                        <div class="formItem">
                        <span class="itemTitle">{{tableAgentInfo[2].title}} <span
                            class="required">*</span> </span>
                            <el-input class="matchParent" v-model="tableAgentInfo[2].content">
                            </el-input>
                        </div>
                        <div class="formItem">
                            <span class="itemTitle">{{tableAgentInfo[3].title}} </span>
                            <el-input class="matchParent" v-model="tableAgentInfo[3].content">
                            </el-input>
                        </div>
                        <div class="formItem">
                            <span class="itemTitle">{{tableAgentInfo[4].title}} </span>
                            <el-input class="matchParent" v-model="tableBaseInfo[4].content">
                            </el-input>
                        </div>
              </div> 
              </el-tab-pane>
              <el-tab-pane label="项目信息">
                <div v-if="formContent">
                  <div class="b-title flex-row-between">
                    <span @click="fillData">项目信息</span>
                    <el-button type="primary" @click="showFormDataDialog">历史表单数据</el-button>
                  </div>
                  <ParseFormViewDiv
                    ref="parseFormViewDiv"
                    :formData="formContent"
                    size="100%"
                  ></ParseFormViewDiv>
                </div>
              </el-tab-pane> 
          </el-tabs>
        </div>
        <div v-show="stepNum === 2 || stepNum === 3" class="d-base-contianer">
          <el-tabs type="border-card"> 
            <el-tab-pane label="上传材料">
              <TableRanking
              :itemList="materialList"
              :itemWorkList="workUpList"
              @uploadFileSuccess="uploadFileSuccess"
              :isBack="isBack"
              ></TableRanking>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div v-show="stepNum === 4" style="padding: 50px"
             class="flex-row flex-center is-justify-center d-base-contianer">
          <h1>{{tip}}</h1>
        </div>
        <div v-show="stepNum !== 4" class="d-btns flex-row flex-center is-justify-center" id="btns">
          <el-button type="warning" v-if="stepNum !== 0" @click="stepNum--">上一步</el-button>
          <!-- <el-button type="info" disabled plain @click="submitAction(true)">暂存</el-button> -->
          <el-button type="primary" @click="nextStep">下一步</el-button>
          <!--<BottomBtns
            @submit="submitAction"
            @tempSave="tempSave"
            @checkInput="checkInput"
          ></BottomBtns>-->
        </div>
      </div>
    </div>
    <el-dialog title="历史表单数据选择" :visible.sync="dgVisible" width="50%">
      <div class="dialog-wrap">
        <div class="mt-10">
          <el-table
            :data="tableData" stripe border height="280" size="mini"
            @row-click="fillFormSubData"
            highlight-current-row>
            <el-table-column
              label="序号"
              type="index"
              width="60"
              align="center"
              style="padding: 0"
            ></el-table-column>
            <el-table-column label="事项名称" min-width="310">
              <template slot-scope="scope">
                <span class="max-one-line">{{ scope.row.sxmc || "" }}</span>
              </template>
            </el-table-column>

            <el-table-column label="申请时间" width="210">
              <template slot-scope="scope">
                <span class="max-one-line">{{ scope.row.createtime.split("T")[0] }}</span>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            class="mt-10"
            background
            hide-on-single-page
            :total="total"
            :page-size="pageSize"
            @current-change="changePageNum"
          ></el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import TableRanking from "@/components/declareComponents/tableRanking.vue";
  import ParseFormViewDiv from "@/components/declareComponents/formMaking/ParseFormViewDiv";
  import {Loading} from "element-ui";
  import {mapGetters} from "vuex";
  import ApplicationNotification from './declareShenpi/components/applicationNotification';		// 申请告知
  export default {
    components: {
      ParseFormViewDiv,
      TableRanking,
      ApplicationNotification,
    },
    computed: {
      ...mapGetters({
        loginUserInfo: "getUserInfo",
        idCardTypes: "getIdCardTypes",
        projectItem: "getCurrentProjectItem", // 从我的项目进入时有值
        childItem: "getCurrentDeclareItem",
        gsBaseInfo: "getAccountBaseInfo",
      }),
    },
    data() {
      return {
        stepNum: 0,
        sxmc: "",
        tip: "业务申报成功,即将进入网上预审阶段, 请留意短信提醒。即将跳转到工作台页面...",
        showFloatingBar: false,
        tabsValue: "baseInfo",
        formData: null, // formData是请求回来的原始数据
        formContent: null, // 根据formContent去渲染页面
        bstype: "1", // 申报类型 1企业2个人 根据用户信息是否有社会信用代码判断
        tabs: [
          {
            label: "基本信息",
            name: "baseInfo",
          },
          {
            label: "附件清单",
            name: "fujian",
          },
        ],
        enterpriseInfo: {
          unitname: "",
          // 机构证件类型
          unitcardtype: "身份证",
          // 统一信用代码
          unitcardcode: "",
          //法人名字
          legalperson: "",
          // 法人身份证
          legalcardtype: "",
          //法人身份证号
          legalcardnum: "",
          unitaddress: "",
          unittel: "",
          unitpostcode: "",
          unitemail: "",
        },
        tableBaseInfo: [], // 基本信息
        tableAgentInfo: [], // 经办人基本信息
        materialList: [], // 网络请求回来的材料列表数据
        workUpList: [], //  通过工作台传的上传
        materials: [], // 与materialList结构一样，上传后增加files字段，如果没上传过材料 提交申报时就用materialList的值
        currentBusinessInfo: null, //从我的项目进入申报页面，获取到是申报的数据
        //事项id(guideId)
        sxid: "",
        allFormDataList: [],
        tableData: [],
        total: 0,
        pageSize: 5,
        pageNum: 1,
        dgVisible: false,
				isBack:"",
      };
    },
    mounted() {
			this.isBack = this.$route.query.isBack
      console.log("childItem:", this.childItem);
      console.log("projectItem:", this.projectItem);
      console.log("loginUserInfo:", this.loginUserInfo);
      console.log("gsBaseInfo", this.gsBaseInfo); 
      if (this.childItem) {
        this.sxid = this.childItem.id
      }
      this.getHistoryFormDataList()
      // 增加滚动监听， 显示申报按钮
      window.addEventListener("scroll", this.handleScroll);
      // 获取证照类型
      if (!this.idCardTypes) {
        console.log("获取词典");
        this.$store.dispatch("getDictListByType", {
          dictType: "sys_id_card_type",
        });
      }
      if (this.loginUserInfo.shxydm) {
        // 申报类型 1企业2个人
        this.bstype = 1;
      } else {
        this.bstype = 2;
      }

      if (this.projectItem) {
        // projectItem有值 则是从我的项目进来的
        this.sxmc = this.projectItem.name || this.projectItem.sxmc || "";
        if (this.projectItem.status !== "3") {
          //暂存件
          this.initTempSaveData();
        } else {
          //其他情况的
          // 如果从我的项目进入则返回所有数据
          this.getBusinessInfo();
        }
      } else {
        this.sxmc = this.childItem.name || this.childItem.ITEM.TASK_NAME || "";
        // 获取表单的数据 并渲染
        this.loadFromData();
        // 获取材料的数据
        this.loadMaterialList();
      }

      this.initBaseInfoUI();
    },
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
      //切换下一步骤
      nextStep() {
        let stepNum = this.stepNum
        if(stepNum === 0) {
          this.refNotification()
        }
        // if(stepNum === 1) {
        //   this.stepNum += 1
        // }
        if (stepNum === 1) {
          //检查基本信息输入
          this.checkBaseInfo().then((res) => {
            console.log("sds", res)
              if (res) {
                this.stepNum += 1;
              }
          })
        }
        if (stepNum === 2) {
          //检查附件必传
          let flag1 = this.checkMaterial() 
          if (flag1) {
            this.$confirm(`开始核实上传信息`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              // this.submitAction()
              this.stepNum += 1;
            }).catch(() => {

            });
          } else {
            // this.$alert('必传材料必须上传才可以申报，您可以选择暂存功能保存当前录入信息。', '提示', {
            //   confirmButtonText: '确定',
            //   callback: action => {
            //   }
            // });
          }
        }

        if (stepNum === 3) { 
          //检查附件必传
          let flag1 = this.checkMaterial()
          if (flag1) {
            this.$confirm(`确认申请事项${this.sxmc}?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.submitAction()
            }).catch(() => {

            });
          } else {
            // this.$alert('必传材料必须上传才可以申报，您可以选择暂存功能保存当前录入信息。', '提示', {
            //   confirmButtonText: '确定',
            //   callback: action => {
            //   }
            // });
          } 
        }
      },
      /**
       * ref=notificationIsconfirm 申请告知 1
       */
      refNotification() {
        let res = this.$refs.notificationIsconfirm;
        console.log("申请告知>>>", res)
        if( !res.btnStatus ) {
          this.$confirm('请确认已经阅读完提示，并点击确认按钮?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
          }).then(() => {
          this.$message({
            type: 'success',
            message: '请点击确认按钮'
          });
          }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
          });
        } else {
          this.stepNum += 1;
        }
      },
      //检查基本信息填写
      async checkBaseInfo() {
        let projectFormData = null;
        projectFormData = await this.$refs.parseFormViewDiv.getFormData();
        if (projectFormData == null) {
          this.$message({
            message: "请先填写必填信息",
            type: "error",
          });
          return false
        }

        let submitData = this.handleSubmitData(false, projectFormData);

        //数据必填验证
        let reporter = submitData.operator.reporter
        let agent = submitData.operator.agent

        if (!this.checkInputReporter(reporter) || !this.checkInputAgent(agent) && !isTempSave) {

          this.$alert('必填信息必须填写才可以申报，您可以选择暂存功能保存当前录入信息。', '提示', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
          return false
        }
        return true
      },
      //检查材料
      checkMaterial() {
        let projectFormData = this.$refs.parseFormViewDiv.getFormData();

        let submitData = this.handleSubmitData(false, projectFormData);

        if (!this.checkFiles(submitData)) {
          this.$alert('必传材料必须上传才可以申报，您可以选择暂存功能保存当前录入信息。', '提示', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
          return false
        }
        return true
      },

      fillData() {
        let data = {
          field101: null,
          name: "深圳太极云软技术有限公司",
          tbrq: "2021-02-26",
          field1: "50",
          zcsj: "1990-08-23 00:00:00",
          shxydm: "22330000MA1G8B3AXE",
          sbbh: "1414121412",
          zcdz: "深圳新一代产业园3栋1402",
          jyfw: "一般经营项目是：计算机软硬件的技术开发及销售，计算机系统集成，计算机技术咨询。",
          account: "12345123456",
          zhmc: "招商银行",
          fddb: "李四",
          qycz: "0755-86309810",
          lxrhm: "14755556666",
          field2: "2019",
          lxryx: "xx@qq.com",
          sshy: [2, 3],
          sndnsed: "12451",
          field6: "2019",
          xsend1: "2019",
          xsendze1: "23",
          xsend2: "2020",
          xsendze2: "25"
        }
        if (this.$refs.parseFormViewDiv) {
          this.$refs.parseFormViewDiv.fillFormData(this.formContent, data);
        }
      },
      //表单数据共享对话框
      showFormDataDialog() {
        this.dgVisible = true
      },
      changePageNum(index) {
        this.pageNum = index
        this.tableData = []
        let allDataList = this.allFormDataList
        let pageSize = this.pageSize
        let pageNum = this.pageNum
        let endIndex
        if (pageNum * pageSize > allDataList.length) {
          endIndex = allDataList.length
        } else {
          endIndex = pageNum * pageSize
        }
        this.total = allDataList.length
        for (let i = (pageNum - 1) * pageSize; i < endIndex; i++) {
          this.tableData.push(allDataList[i])
        }
      },
      /**
       * 历史表单数据共享获取
       */
      getHistoryFormDataList() {
        let userInfo = this.$store.getters.getUserInfo
        let param = {
          sxid: this.sxid
        }
        if (userInfo) {

          if (this.bstype == 1) {
            //企业,查询数据使用社会信用统一代码
            param.tyxydm = userInfo.shxydm
            // param.tyxydm = '91310000MA1G8B8AXD'

          } else {
            //个人,查询数据使用身份证号码
          }
        }
        console.log("历史表单数据共享参数>>>", param)
        this.$httpApi.get("/dev-api/userBusiness/list", param).then((res) => {
          if (res.code === 200 && res.rows.length > 0) {
            console.log("res>>>", res);
            let resList = JSON.parse(JSON.stringify(res.rows))
            // 处理数据
            this.allFormDataList = resList
            // this.tableData = resList
            let allDataList = this.allFormDataList
            let pageSize = this.pageSize
            let pageNum = this.pageNum
            let endIndex

            if (pageNum * pageSize > allDataList.length) {
              endIndex = allDataList.length
            } else {
              endIndex = pageNum * pageSize
            }
            this.total = allDataList.length
            for (let i = (pageNum - 1) * pageSize; i < endIndex; i++) {
              this.tableData.push(allDataList[i])
            }
          }
        })
          .catch((e) => {
          });

      },
      /**
       * 表单数据共享点击事件
       */
      fillFormSubData(itemdata) {
        console.log("表单数据共享点击>>>", itemdata)
        this.getFormInfo(itemdata.id)
        // 填充表单
        setTimeout(() => {
          let data = this.formData;
          console.log("填充的表单数据:", data);
          this.fillFormData(data);
          //关闭对话框
          this.dgVisible = false
        }, 100);
      },
      /**
       * 点击 依据事件ID查询表单信息
       *
       * @param { String } id [ 传入的 ID ]
       * @return {  } [ 返回的数据，带表单 ]
       */
      getFormInfo(el) {
        let loadingInstance = Loading.service({
          target: this.$refs.declareContainer,
        });
        let url = "/dev-api/userBusiness/queryFormData"
        let params = {
          bsnum: el
        }
        this.$httpApi.get(url, params)
          .then((res) => {
            loadingInstance.close();
            console.log("11111111111111111111111111", res)
            if (res.code === 200) {
              let target = res.data.subdata
              let resData = JSON.parse(target)
              console.log("转义后的resData", resData)
              this.formData = resData

            }
          })
          .catch((err) => {
            loadingInstance.close();
          })
      },
      // 更新基本信息、经办人信息UI
      initBaseInfoUI() {
        if (this.gsBaseInfo) {
          this.enterpriseInfo = {
            unitname: this.gsBaseInfo.name || "",
            unitcardtype: "100008", // 机构证件类型  100008是工商营业执照
            unitcardcode: this.gsBaseInfo.shxydm || "", // 统一信用代码
            legalperson: this.gsBaseInfo.frdb || "", //法人名字
            legalcardtype: "100001", // 法人证件类型 100001是身份证
            legalcardnum: this.gsBaseInfo.frsfz || "****", //法人身份证
            unitaddress: this.gsBaseInfo.zcdz || "",
            unittel: this.gsBaseInfo.qycz || "",
            unitpostcode: "000000",
            unitemail: this.gsBaseInfo.qyyx || "",
          };
        }

        if (this.bstype == 1) {
          // 企业申报
          //  用于显示企业的基本信息
          this.tableBaseInfo = [
            {title: "姓名", content: this.enterpriseInfo.unitname},
            {title: "证件号码", content: this.enterpriseInfo.unitcardcode},
            {title: "证件地址", content: this.enterpriseInfo.unitaddress},
            {title: "代表人姓名", content: this.enterpriseInfo.legalperson},
            {title: "代表人证件", content: this.enterpriseInfo.legalcardnum},
            {title: "机构电话", content: this.enterpriseInfo.unittel},
            {title: "申报对象", content: "单位"},
          ];
        } else {
          // 个人申报
          // 用于显示个人的基本信息
          this.tableBaseInfo = [
            {title: "姓名", content: this.loginUserInfo.name || this.loginUserInfo.nickName ||""},
            {title: "证件类型", content: "个人"},
            {title: "电话", content: this.loginUserInfo.phone || this.loginUserInfo.enterpriseInfo.phone || ""},
            {
              title: "证件号码",
              content: this.loginUserInfo.idcard || "430623********8336",
            },
            {title: "邮箱", content: this.loginUserInfo.email || "153****66@qq.com"},
            {
              title: "地址",
              content: this.loginUserInfo.address || "",
            },
          ];
        }
        if (this.loginUserInfo) {
          // 登录人就是经办人 tableAgentInfo数组用于显示经办人的信息
          this.tableAgentInfo = [
            {title: "姓名", content: this.loginUserInfo.name || this.loginUserInfo.nickName || ""},
            {
              title: "证件号码",
              content: this.loginUserInfo.idcard || "***",
            },
            {title: "手机号码", content: this.loginUserInfo.phone || this.loginUserInfo.enterpriseInfo.phone || ""},
            {
              title: "固定电话",
              content: this.loginUserInfo.address || "",
            },
            {
              title: "证件地址",
              content: this.loginUserInfo.address || "",
            },
            // { title: "经办人邮箱", content: this.loginUserInfo.email || "" },

          ];
        }
      },
      // 从我的办件进入时 获取办件详情信息
      getBusinessInfo() {
        if (!this.projectItem) {
          return;
        }
        let loadingInstance = Loading.service({
          target: this.$refs.declareContainer,
        });
        this.$nextTick(() => {
          let formData = new FormData();
          formData.append("id", this.projectItem.id);
          this.$httpApi
            .post("/dev-api/userBusiness/getBusinessInfo", formData)
            .then((res) => {
              loadingInstance.close();
              console.log("从我的办件进入时 获取办件详情信息>>>>>>>", res);
              if (res.code == 200) {
                this.initBusinessInfoData(res.data);
              }
            })
            .catch((err) => {
              loadingInstance.close();
            });
        });
      },
      // 获取到的办件详情进行渲染
      initBusinessInfoData(res) {
        let resData = res.material;
        // 保存下请求的值
        this.currentBusinessInfo = res;

        // 事项名称
        if (res.masterinfo) {
          this.currentBusinessInfo.masterinfo.bsnum = res.masterinfoReporter.bsnum;
        }

        /* 附件清单，从我的工作台进去的 */
        let target = [];
        for (const item of resData) {
          item.dgVisible = false;
          item.files = item.masterinfoList;
          for (const i in item.files) {
            let f = item.files[i];
            f.name = f.filename || f.fileName;
          }
          target.push(item);
        }
        console.log("上传相关，从我的工作台进去的>>>", target);
        this.materialList = [];
        this.workUpList = target;

        this.formData = res.formData;
        if (this.formData) {
          // 解析表单显示UI
          this.formContent = JSON.parse(this.formData.formdata)
          // 填充表单
          setTimeout(() => {
            const data = JSON.parse(this.formData.subdata);
            console.log("填充的表单数据1111111:", data);
            this.fillFormData(data);
          }, 500);
        }
      }
      ,
      initTempSaveData() {
        console.log("initTempSaveData:", this.projectItem);
        // 给材料列表赋值
        let materialList = this.projectItem.bsdata.materials;
        /* 附件清单，从我的工作台进去的 */
        let target = [];
        for (const item of materialList) {
          item.dgVisible = false;
          for (const i in item.files) {
            let f = item.files[i];
            f.name = f.filename || f.fileName;
          }
          target.push(item);
        }
        console.log("上传相关，从我的工作台进去的>>>", target);
        // TODO: materialList为空数据不好吧
        this.materialList = [];
        this.workUpList = target;

        // 渲染表单
        this.formData = this.projectItem.bsdata.formInfo[0];
        if (this.formData) {
          // 解析表单显示UI
          this.formContent = JSON.parse(this.formData.formdata);
          // 填充表单
          setTimeout(() => {
            const data = JSON.parse(this.formData.subdata);
            console.log("填充的表单数据:", data);
            this.fillFormData(data);
          }, 500);
        }
      }
      ,
      /* 上传的文件信息 */
      uploadFileSuccess(e) {
        console.log("upload OK: ", e);
        this.materials = e;
      }
      ,
      // 加载表单的json数据，进行渲染表单
      loadFromData() {
        if (this.childItem) {
          // this.childItem.formid = this.childItem.itemid;
          if (!this.childItem.formid) {
            console.log("----------------- 表单ID不能为空 -----------------");
            return;
          }
          let loadingInstance = Loading.service({
            target: this.$refs.declareContainer,
          });
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            this.$httpApi
              .get(`/dev-api/basicinfomanage/info/${this.childItem.formid}`, {})
              .then((res) => {
                console.log("res表单数据>>>:", res);
                loadingInstance.close();
                if (res.code == 200) {
                  this.formData = res.data;
                  if (this.formData.formContent) {
                    this.formContent = JSON.parse(this.formData.formContent);
                    // console.log("处理后的表单数据>>>" + JSON.stringify(this.formContent));
                    setTimeout(() => {
                      this.fillFormData();
                    }, 500);
                  }
                }
              })
              .catch((err) => {
                loadingInstance.close();
              });
          });
        }
      }
      ,
      /**
       * 填充表单数据
       */
      fillFormData(data) {
        if (data == null) {
          // 假数据 这数据应该是从后台请求到的
          data = this.gsBaseInfo;
        }
        if (this.$refs.parseFormViewDiv) {
          this.$refs.parseFormViewDiv.fillFormData(this.formContent, data);
        }
      }
      ,
      loadMaterialList() { 
        if (this.childItem && this.childItem.clxxList) {
          let resData = this.childItem.clxxList;
          let target = [];
          for (const item of resData) {
            item.dgVisible = false;
            item.clmc = item.MATERIAL_NAME; // 材料名称 
            item.isneed = item.IS_NEED_TEXT === "是" ? 1 : 0; // 是否必须 
            target.push(item);
          }
          this.materialList = target;
        }
      }
      ,
      /**
       * 处理表单数据
       */
      handleSubmitData(isTempSave, projectFormData) {
        let submitData = {};
        // 从我的项目进入 status === 6 是表示暂存 
        if (this.projectItem) { 
          let bsdata = this.projectItem.bsdata;
          let formInfo = [
            {
              bsnum: this.formData.bsnum || "", // 业务流水号
              id: this.formData.id || "", // 业务表单ID 新申报时为空
              formdata: JSON.stringify(this.formContent), // 这个可以不传
              subdata: projectFormData ? JSON.stringify(projectFormData) : "",
              formtype: this.formData.formtype || "0", // 0 业务表单；1 在线填报表单,11:文书表单,10基本表单，12特别程序表单
              formid: this.formData.formid,
            },
          ];
          //如果有修改了附件的 则使用数组this.materials，否则使用网络请求的附件数组
          let materials;
          if (this.materials && this.materials.length > 0) {
            materials = this.materials;
          } else {
            materials = this.materialList.length > 0 ? this.materialList : this.workUpList;
          }
          submitData = {
            baseinfo: bsdata.baseinfo, // 基本信息
            formInfo: formInfo, // 表单信息
            materials: materials, // 材料信息
            operator: bsdata.operator,
          };
          console.log("从待办件进来后的要提交的数据:", submitData);
          return submitData;
        } else {

          let childItem;
          if (this.currentBusinessInfo && this.currentBusinessInfo.masterinfo) {
            // 从在办件进入masterinfo才有值
            childItem = this.currentBusinessInfo.masterinfo;
          } else {
            childItem = this.childItem;
          }

          let formInfo = [
            {
              bsnum: childItem.bsnum || "", // 业务流水号
              id: this.formData.id || "", // 业务表单ID 新申报时为空
              formdata: JSON.stringify(this.formContent), // 这个可以不传
              subdata: projectFormData ? JSON.stringify(projectFormData) : "{}",
              formtype: childItem.formtype || "0", // 0 业务表单；1 在线填报表单,11:文书表单,10基本表单，12特别程序表单
              formid: childItem.formid,
            },
          ]; 

          // let infoItem = childItem.ITEM 
          let baseInfo = {
            areaid: "", //暂时传空
            areaname: childItem.regionName || "",//***
            sxid: childItem.id ||  "", // 事项ID
            bsnum: childItem.bsnum || "",//***
            bstype: childItem.bstype || this.bstype || "0", // 业务类型1企业2个人
            cnsj: childItem.cnsj || "", // 承诺时间
            cnsjdw: childItem.cnsjdw || "", //承诺时间单位  ***
            deptid: childItem.deptCode || childItem.deptid || "",//暂时传空,后期调整 ***
            deptname: childItem.deptName || childItem.deptname ||  "",
            sxmc: childItem.name || childItem.sxmc ||  "", // 事项名称
            jljb: childItem.jljb || "", // 是否即办件 ***
            flowid: childItem.flowId || childItem.flowId || "", // 办理流程ID ***
            applicantid: childItem.applicantid || this.loginUserInfo.id || "", // 申请人ID
            appname: childItem.appname || this.loginUserInfo.name || this.loginUserInfo.nickName ||"", // 申请人姓名
            appcompany: childItem.appcompany || this.enterpriseInfo.unitname || "", // 申请人公司

            type: childItem.handerType || "1", // 业务来源 1PC 2窗口
            status: childItem.status || "", // 业务办理状态 0在办1正常办结2异常办结3补交4特别程序5预受理 ***
            ssgzr: childItem.ssgzr || "", // 所属工作日 ***
          };
          //todo 目前是走个人的,后续得加判断是否是企业bstype==1

          // 经办人信息
          let agentInfo = {
            agentuserid: this.loginUserInfo.id,
            agentname: this.tableAgentInfo[0].content || "",
            agentcertificatetype: "100001",
            agentcertificatename: "身份证",
            agentcertificatenumber: this.tableAgentInfo[1].content || "***",
            agentmobilephone: this.tableAgentInfo[2].content || "",
            agentgender: "2", //性别
            agentbirthday: this.loginUserInfo.birthday || "",
            agentaddress: this.loginUserInfo.address || "",
            agentemail: this.loginUserInfo.email || "",
          };

          // 申报人信息
          /**
           * 20210426
           * TODO
           * 目前改用扫码登录后，没有loginId了
           * 是否可以在上传和查询的时候，都统一用别的字段代替？
           */
          let reporter = {
            operateid: this.loginUserInfo.id || "",
            operateaccount: this.loginUserInfo.userId || "",
            // operateaccount: this.loginUserInfo.loginId || "",
            optname: this.tableBaseInfo[0].content || "",
            optcertificatetype: "100001",
            optcertificatename: "身份证",
            optcertificatenumber: this.tableBaseInfo[3].content,
            optmobilephone: this.tableBaseInfo[2].content || "",
            optgender: "1",
            optbirthday: this.loginUserInfo.birthday || "",
            optaddress: this.tableBaseInfo[5].content || "",
            optemail: this.tableBaseInfo[4].content || "",
          };

          // 再次申报的时候 要用到currentBusinessInfo的值进行赋值
          if (this.currentBusinessInfo) {
            reporter = this.currentBusinessInfo.masterinfoReporter;
          }

          //如果有修改了附件的 则使用数组this.materials，否则使用网络请求的附件数组
          let materials;
          if (this.materials && this.materials.length > 0) {
            materials = this.materials;
          } else {
            materials = this.materialList.length > 0 ? this.materialList : this.workUpList;
            // 循环赋值，将样表里面的参数多存一个yburl
          }
          console.log("材料", this.materialList)
          //处理相关字段,避免申报出错 
          for (let i = 0; i < materials.length; i++) {
            let guid = materials[i].ROWGUID
            let isneed = materials[i].isneed
            let yburl = "";  
            /**
             * 20210422
             * EXAMPLE_GUID 原来是个数组吗？现在看到是个对象
             */
            // if (materials[i].EXAMPLE_GUID.FILEPATH) {
            //   yburl = materials[i].EXAMPLE_GUID.FILEPATH
            // } else {
            //   yburl = ""
            // }

            // materials[i].clbm = guid
            // materials[i].clid = guid
            materials[i].clmc = materials[i].clname
            materials[i].yburl = yburl
            materials[i].isneed = isneed;

          }
          
          submitData = {
            baseinfo: baseInfo, // 基本信息
            formInfo: formInfo, // 表单信息
            materials: materials, // 材料信息
            operator: {
              apptype: this.bstype, // 申报类型 1企业 2个人
              enterprise: this.enterpriseInfo, // 企业信息 
              personal: {
                applicantid: this.loginUserInfo.id || "",
                appname: "申请人",
                certificatetype:  "100001",
                certificatename: "身份证",
                certificatenumber: this.tableBaseInfo[3].content,
                mobilephone: this.tableBaseInfo[2].content || "",
                gender: "1",
                birthday: this.loginUserInfo.birthday || "",
                address: this.tableBaseInfo[5].content || "",
                email: this.tableBaseInfo[4].content || "",
              },
              agent: agentInfo, // 经办人信息
              reporter: reporter,
            },
          };
          console.log("提交的参数>>>:", submitData);
          return submitData;
        }
      }
      ,

      /* 提交按钮 */
      async submitAction(isTempSave) {

        let projectFormData = null;
        if (isTempSave) {
          projectFormData = await this.$refs.parseFormViewDiv.getTempSaveData();
        } else {
          projectFormData = await this.$refs.parseFormViewDiv.getFormData();
        }
        console.log(JSON.stringify(projectFormData));
        //验证基本信息是否填写

        if (projectFormData == null && !isTempSave) {
          // 暂存时不校验数据
          if (this.tabsValue != "baseInfo") {
            // 先切换为表单baseInfo页面，让用户知道哪些信息该填
            this.tabsValue = "baseInfo";
          }
          this.$message({
            message: "请先填写必填信息",
            type: "error",
          });
          return;
        }
        let submitData = this.handleSubmitData(isTempSave, projectFormData);

        //数据必填验证
        let reporter = submitData.operator.reporter
        let agent = submitData.operator.agent

        if (!this.checkInputReporter(reporter) || !this.checkInputAgent(agent) && !isTempSave) {

          this.$alert('必填信息必须填写才可以申报，您可以选择暂存功能保存当前录入信息。', '提示', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
          return
        }

        //附件材料检查
        if (!this.checkFiles(submitData) && !isTempSave) {
          this.$alert('必传材料必须上传才可以申报，您可以选择暂存功能保存当前录入信息。', '提示', {
            confirmButtonText: '确定',
            callback: action => {
            }
          });
          return
        }
        // 开始提交
        const loading = Loading.service({
          fullscreen: true,
        });

        let url = "/dev-api/business/handle/report";
        if (isTempSave) {
          url = "/dev-api/business/handle/zancun";
        }
        console.log("表单+++++++++++++++++++++++++++++++++++++++++++++++++", submitData)
        this.$httpApi
          .post(url, submitData)
          .then((res) => {
            loading.close();
            console.log("提交表单结果:", res);
            if (res.code == 200) {
              this.$message({
                message: isTempSave ? "暂存成功" : "提交成功",
                type: "success",
              });
              this.stepNum = 4;
              if (!isTempSave) {
                //会显示提示成功的页面
                this.tip = "业务申报成功,即将进入网上预审阶段, 请留意短信提醒。即将跳转到工作台页面..."
              } else {
                this.tip = "业务暂存成功,即将跳转到工作台页面..."
              }
              setTimeout(() => {
                this.$router.replace({path: "/workplace/index"});
              }, 3000);
            } else {
              this.$message({
                message: "提交失败",
                type: "error",
              });
            }
          })
          .catch((err) => {
            loading.close();
            this.$message({
              message: "网络异常，请稍后重试",
              type: "error",
            });
          });
      },
      //检查必须的文件列表
      checkFiles(submitData) {
        // 必传材料验证

        if (submitData.materials && submitData.materials.length > 0) {
          let materialList = submitData.materials;
          for (let i = 0; i < materialList.length; i++) {
            let item = materialList[i]
            if (item.IS_NEED === "1" || item.isneed === "1") {
              if (!item.files || item.files.length <= 0) {
                return false
              }
            }
          }
        }
        return true;
      },
      //申请人(法人)输入验证
      checkInputReporter(item) {
        if (item.optname == "") {
          return false
        }
        if (item.optmobilephone == "") {
          return false
        }
        if (item.optcertificatenumber == "") {
          return false
        }
        if (item.optemail == "") {
          return false
        }
        return true;
      },
      //经纪人表单输入验证
      checkInputAgent(item) {

        if (item.agentname == "") {
          return false
        }
        if (item.agentcertificatenumber == "") {
          return false
        }
        if (item.agentmobilephone == "") {
          return false
        }
        return true
      },
      tempSave() {
        // console.log("暂存");
        this.submitAction(true);
      }
      ,
      checkInput() {
        console.log("checkInput");
      }
      ,

      handleScroll() {
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        let btnsoffsetTop = document.querySelector("#btns").offsetTop;
        // console.log("btn:", scrollTop, btnsoffsetTop, window);
        if (scrollTop > btnsoffsetTop - window.innerHeight) {
          this.showFloatingBar = false;
        } else {
          this.showFloatingBar = true;
        }
      }
      ,

      savepdf() {
        console.log("start loading。。。");
        let vm = this;
        const A4_WIDTH = 592.28;
        const A4_HEIGHT = 841.89;
        vm.$nextTick(async () => {
          let target = vm.$refs.pdf;
          vm.getPdf(target, "文件名");
        });
      }
      ,
    },
  }
  ;
</script>

<style lang="scss">
  @import "@/styles/index.scss";

  .formItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;

    .itemTitle {
      width: 120px;
      font-weight: 600;
    }

    .required {
      color: red;
    }
  }

  .matchParent {
    flex: 1 1 0%;
  }

  .declare {
    // background: #fff;
    .banner-size {
      background: url("../../assets/images/icon_banner_declare.png");
    }

    .banner-info {
      height: 100%;
      display: flex;
      align-items: center;

      .banner-other {
        margin-left: 15px;
      }
    }
  }

  .declare-container {
    background-color: #ffffff;

    .d-container {
      padding: 30px 24px;

      .d-title {
        font-size: 24px;
        color: #000;
        font-weight: 600;
        padding-bottom: 30px;
        margin-right: 30px;
      }
    }

    .d-btns {
      padding: 40px 0;
    }
  }

  .d-base-contianer {
    //   margin: 0 200px;
    margin-top: 20px;
    min-height: 400px;

    .b-title {
      padding: 12px 0;
      margin-top: 40px;
      margin-bottom: 20px;
      border-bottom: 4px solid #000;
      color: #000;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .floatingBar {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 9999;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    transition: all 0.2s ease;
  }
</style>
